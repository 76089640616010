.loading-message-container {
  position: absolute;
  left: 40%;
  top: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    color: #b19777;
    font-size: 48px;
    text-transform: capitalize;
  }
}

@media (min-width: 275px) and (max-width: 550px) {
  .loading-message-container {
    left: 20%;
    top: 30%;

    .title {
      font-size: 14x;
    }
  }
}
